import {Controller} from 'stimulus'
import {Loader} from '@googlemaps/js-api-loader';
import {MarkerClusterer} from "@googlemaps/markerclusterer";

/**
 * Displays a Google Map with multiple addresses (In case no coordinates are provided, the Geocoding API is used)
 *
 * API KEY:
 *  The Google Maps API Key is required for this to work
 *  Example config: data-multiple-addresses-map-api-key="<%= Rails.application.config.google_maps[:api_key] %>"
 *
 * Addresses:
 *  A list of strings, or a list of latitudes and longitudes
 *  If a string is Provided, the Geocoding API is called to get the coordinates for the string address
 *  If the coordinates are provided they are used directly to place the markers
 *  Example Strings: ["Windscheidstraße 29, 04277 Berlin", "Zweinaundorfer Straße 1, 04318 Cologne"]
 *  Example Coordinates: [{"latitude": "51.3113297", "longitude": "-12.3692492"}, {"latitude": "51.3356419", longitude": "-12.3698492"}]
 *  Example config: data-multiple-addresses-map-addresses="<%= portfolio.addresses_data_for_map %>"
 *  Note, inside the addresses array, you can also provide an infowindow object to display on marker click,
 *  it can contain the HTML or an URL to fetch the HTML
 *  E.g: [{latitude ..., infowindow: {contentURL: "/app/realties/1?variant=map"} }
 *  or: [{latitude ..., infowindow: {content: "<div>This is the content</div>"} }
 */
export default class extends Controller {
  initialize() {
    this.addresses = JSON.parse(this.data.get('addresses') || '[]')
    this.apiKey = this.data.get('api-key') || null
    this.clustered = this.data.get('clustered') === 'true'
  }

  connect() {
    const loader = new Loader({
      apiKey: this.apiKey
    });
    loader.load().then(google => {
      this.google = google
      try {
        this.initializeMap()
      } catch (e) {
        this.element.innerHTML = "There was an error loading the map..."
        console.error(e)
      }
    }, function(err) {
      this.element.innerHTML = "There was an error loading the map..."
    });
  }

  async initializeMap() {
    this.map = new this.google.maps.Map(this.element, {
      zoom: 6,
      center: { lat: 50, lng: 6 },
      maxZoom: 19,
      minZoom: 2,
      mapTypeControl: false,
    })

    this.geocoder = new this.google.maps.Geocoder()
    this.infoWindows = []

    this.google.maps.event.addListener(this.map, "click", () => {
      for (const infowindow of this.infoWindows) {
        infowindow.close();
      }
    })

    // Convert addresses into latitudes and longitudes
    const promises = this.addresses.map(async (address) => {
      try {
        address = JSON.parse(address);
      } catch {
        // It's just a string
      }
      const loc = {};
      if (address.latitude && address.longitude) {
        loc.lat = +address.latitude;
        loc.lng = +address.longitude;
        loc.infowindow = address.infowindow;
        return loc;
      }
      const result = await this.geocodeAddress(address);
      if (result.position) {
        loc.lat = result.position.lat();
        loc.lng = result.position.lng();
      }
      return loc;
    });
    const results = await Promise.all(promises);
    this.locations = results.filter(loc => !!loc.lat && !!loc.lng)

    if (this.locations.length > 0) {
      const markers = [];
      const bounds = new this.google.maps.LatLngBounds()
      for (const location of this.locations) {
        const marker = new this.google.maps.Marker({
          position: location,
          map: this.map,
        })
        markers.push(marker)
        bounds.extend(new this.google.maps.LatLng(location.lat, location.lng))

        if (location.infowindow) {
          this.addInfoWindow(location.infowindow, marker)
        }
      }
      // Fit map to markers
      this.map.fitBounds(bounds)
      this.map.setZoom(this.map.getZoom() - 1)

      if (this.clustered) {
        // Add a marker clusterer to manage the markers.
        new MarkerClusterer({markers, map: this.map})
      }
    }
  }

  addInfoWindow(infoWindowConfig, marker) {
    if (infoWindowConfig.content) {
      const infowindow = new google.maps.InfoWindow({
        content: infoWindowConfig.content,
      })
      this.infoWindows.push(infowindow)

      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        });
      })
    } else if (infoWindowConfig.contentURL) {
      marker.addListener("click", async () => {
        const infowindow = new google.maps.InfoWindow({
          content: '<div>...</div>'
        })
        infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        })

        try {
          const data = await fetch(infoWindowConfig.contentURL)
          if (data.ok) {
            infowindow.setContent(await data.text())
            // Re-open it so that Google maps would auto-pan to this infowindow using new content size
            infowindow.open({
              anchor: marker,
              map: this.map,
              shouldFocus: false,
            })
            // Google maps keeps auto panning on zoom, therefore disable it after open
            infowindow.setOptions({ disableAutoPan: true })
          } else if (data.status === 404) {
            infowindow.setContent('404!')
          }
          else {
            infowindow.setContent('There was an error while loading the info!')
          }
        } catch (e) {
          infowindow.setContent('There was an error while loading the info!')
          console.error(e)
        }
        this.infoWindows.push(infowindow)
      })
    }
  }

  geocodeAddress(address) {
    return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
      this.geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const position = results[0].geometry.location
          resolve({
            position,
          })
        } else {
          resolve({
            error: `Geocode was not successful for the following reason: ${status}`,
          })
        }
      })
    })
  }
}
